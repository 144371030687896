.error-container{
    background-color: rgb(255, 140, 0);
    padding: 15px 0px;
    border-radius: 10px;
    color: white;
    font-family: sans-serif;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    overflow-x: hidden;

}

.error-container li{
    padding-top: 10px;
}

@media (max-width:450px) {
    .error-container li{
        font-size: 13px;
    }
}

@media (max-width:370px) {
    .error-container li{
        font-size: 10px !important;
    }
}